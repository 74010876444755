@tailwind base;
@tailwind components;
@tailwind utilities;
@import './date-picker.css';
@import "./value-slider.css";

html,body {
  box-sizing: border-box;
}

*, *:before, *:after { box-sizing: inherit; -webkit-font-smoothing: antialiased;}

html {
  height: 100%;
  width: 100%;
  @apply font-plex;
}

html,
body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  word-wrap:break-word;
}

#appContainer {
  min-height: 100vh;
  margin: 0 auto;
  min-width: 18.75em;
}

#appContainer.embed {
  padding-top: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.container {
    @apply px-8 max-w-[1800px];
  }

@layer base {

  .container {
    max-width: 1400px;
  }

  .safari-full-vh {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }

  .full-height {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: -webkit-fill-available;
  }

  /* FONTS */
  h1, h2, h3, h4 {
    @apply font-barlow my-[12px];
  }

  p, .p, input {
    @apply font-plex text-[17px];
  }

  a.link {
    @apply text-yammy-100 underline;
  }

  h1, .h1 {
    @apply text-[56px] font-extrabold uppercase;
  }

  h2, .h2 {
    @apply text-[34px] font-semibold uppercase;
  }

  h3, .h3 {
    @apply text-[26px] font-medium uppercase;
  }

  h4, .h4 {
    @apply text-[20px] font-semibold uppercase;
  }

  h5, .h5 {
    @apply text-[20px] font-extrabold tracking-wider uppercase;
  }

  h6, .h6 {
    @apply text-[20px] font-black tracking-widest uppercase;
  }

  @media screen and (min-width: 768px) {
    h1, .h1 {
      @apply text-[64px];
    }

    h2, .h2 {
      @apply text-[45px];
    }

    h3, .h3 {
      @apply text-[32px];
    }

    h4, .h4 {
      @apply text-[25px];
    }

    p, .p, input {
    }
  }

  /* BUTTONS */
  .button {
    @apply font-barlow uppercase px-8 py-4 border rounded-md transition ease-in-out duration-300 disabled:bg-mid-grey disabled:border-mid-grey disabled:text-dark-grey select-none whitespace-nowrap text-center flex items-center justify-center;
  }

  .button.small {
    @apply px-4 py-2 text-sm;
  }

  .button-primary {
    @apply border-off-black text-white bg-off-black hover:bg-yammy-100 hover:border-yammy-100;
  }

  .button-primary.dark {
    @apply border-white text-off-black bg-white hover:bg-kato-100 hover:text-white hover:border-kato-100;
  }

  .button-secondary {
    @apply border-off-black text-black bg-white hover:bg-off-black hover:border-off-black hover:text-white;
  }

  .button-secondary.dark {
    @apply border-white text-white bg-off-black hover:bg-white hover:text-off-black;
  }

  .button-tertiary {
    @apply border-kato-100 text-white bg-kato-100 hover:bg-kato-75 hover:border-kato-75;
  }

  .button-quaternary {
    @apply border-yammy-100 text-white bg-yammy-100 hover:bg-yammy-75 hover:border-yammy-75;
  }

  .button.disabled {
    @apply pointer-events-none bg-dark-grey text-light-grey border-dark-grey cursor-not-allowed;
  }

  input, select {
    @apply border border-transparent;
  }

  input:focus, select:focus {
    @apply outline-none border border-kato-75;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
}


/* Override NextJS stupid Image styling */
.image-container {
  width: 100%;
}

.image-container > span {
  position: unset !important;
}

.image-container img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.image-container.cover img {
  object-fit: cover;
}

/* Pagination */

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin: 16px 0;
  text-transform: uppercase;
  padding-bottom: 32px;
  @apply text-xl;
}

.pagination li {
  @apply flex items-center
}

.pagination li a {
  padding: 16px;
}

.pagination li.active {
  @apply text-kato-100;
}

.pagination li.disabled a {
  cursor: not-allowed;
  opacity: 0.25;
}

/* Google Maps */
.event-page__google-map {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
  @apply rounded-t-md;
}

.event-page__google-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.animation-flip {
  animation: flip 1.5s infinite ease-in-out;
}

@keyframes flip
{
  100% {
    transform: rotateY(360deg);
    transform-origin: center;
  }
}

.bg-shimmer {
  animation : shimmer 3s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}